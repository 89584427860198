<template>
  <article>
    <p class="menu-label">
      <span class="title is-6">
        Return No. {{ returnDetail.number }}
      </span>
    </p>
    <div>
      <div class="columns">
        <div class="column field">
          <div class="field">
            <label class="label">Vendor ABN</label>
          </div>
          <div class="control has-icons-right">
            <the-mask v-if="$company.info.countryCode === 'AU'"
              id="abn"
              :class="{ 'is-static' : vendorId === '', 'is-danger' : $v.entitySummaries.vendorAbn.$error}"
              name="abn"
              ref="abn"
              class="input abn"
              :disabled="readOnly"
              v-model="returnDetail.abn"
              @change="changeValue()"
              placeholder="Vendor ABN"
              :mask="['## ### ### ###']" />
            <input v-else
              v-model="returnDetail.abn"
              id="abn"
              ref="abn"
              class="input is-uppercase"
              @change="changeValue()"
              maxlength="12">
            <span v-if="!$v.entitySummaries.vendorAbn.$error && $v.entitySummaries.vendorAbn.validAbn && $v.entitySummaries.vendorAbn.minLength && $v.entitySummaries.vendorAbn.required && $company.info.countryCode === 'AU'"
              class="icon has-text-success is-right">
              <i class="mdi mdi-check mdi-18px" />
            </span>
            <span class="help is-danger"
              v-if="returnDetail.abn === '' && $company.info.isWithHold">
              This vendor does not have an ABN. You will be required to with hold {{ $userInfo.withHoldingTax }}% of the invoice.
            </span>
            <span class="help is-danger"
              v-else-if="$v.entitySummaries && !$v.entitySummaries.vendorAbn.validAbn">ABN is invalid.</span>
            <span class="help is-danger"
              v-if="$v.entitySummaries && !$v.entitySummaries.vendorAbn.minLength">ABN must be at least {{ $v.entitySummaries.vendorAbn.$params.minLength.min }} letters.</span>
          </div>
        </div>
        <div class="column field">
          <div class="control">
            <div class="field">
              <label class="label is">Return Date</label>
            </div>
            <div class="field is-narrow is-pulled-left">
              <v-date-picker v-model="returnDetail.date"
                :masks="formats"
                :attributes="attrs"
                :locale="$userInfo.locale"
                @input="returnDetail.date = $filters.fromISOWithCurrentTime($event.toISOString())"
                :timezone="$filters.getIanaTimezone()">
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="field">
                    <div class="control has-icons-left">
                      <input v-if="returnDetail.id === '' || readOnly"
                        type="text"
                        class="input"
                        readonly
                        :class="{'is-danger' : $v.entitySummaries.returnDate.$invalid}"
                        :disabled="true"
                        placeholder="Select Credit Date"
                        :value="$filters.formatDateTimezone(returnDetail.date, $userInfo.locale)">
                      <input v-else
                        type="text"
                        class="input"
                        readonly
                        @input="changeValue()"
                        :class="{'is-danger' : $v.entitySummaries.returnDate.$invalid}"
                        placeholder="Select Credit Date"
                        :value="inputValue"
                        v-on="inputEvents">
                      <span class="icon is-small is-left">
                        <i class="mdi mdi-calendar mdi-18px" />
                      </span>
                      <span class="help is-danger"
                        v-if="!$v.entitySummaries.returnDate.required">Return Date is required.
                      </span>
                    </div>
                  </div>
                </template>
              </v-date-picker>
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>
<script>
import { NumberFiltersMixin, DateTimeFiltersMixin } from '@/components/mixins/filters'
// import _cloneDeep from 'lodash/cloneDeep'
import { mask } from 'vue-the-mask'
import { TheMask } from 'vue-the-mask'
// import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'

export default {
  name: 'ReturnPartsDetailValue',
  inject: ['$vv'],
  components: {
    TheMask
  },
  directives: {
    mask
  },
  mixins: [NumberFiltersMixin, DateTimeFiltersMixin],
  props: {
    vendorAbn: String,
    returnNo: String,
    vendorId: String,
    returnDate: String,
    readOnly: Boolean
  },
  data() {
    return {
      isReturnedPartsValue: '1',
      returnDetail: {
        abn: this.vendorAbn,
        number: this.returnNo,
        id: this.vendorId,
        date: this.returnDate
      },
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ]
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    }
  },
  watch: {
    vendorId: {
      handler: function (newVal, oldVal) {
        this.returnDetail.id = newVal
      }
    },
    vendorAbn: {
      handler: function (newVal, oldVal) {
        this.returnDetail.abn = newVal
      }
    },
    'returnDetail.abn': {
      handler: function (newVal, oldVal) {
        this.changeValue()
      }
    },
    'returnDetail.date': {
      handler: function (newVal, oldVal) {
        this.changeValue()
      }
    }
  },
  mounted() {
    this.$v.detailGroup.$touch()
  },
  created() {},
  methods: {
    changeValue() {
      // this.returnDetail.abn = this.vendorAbn
      // this.returnDetail.no = this.returnNo
      // this.returnDetail.id = this.vendorId
      let date = new Date(this.returnDetail.date)
      this.returnDetail.date = date.toISOString().split('.')[0] + 'Z'
      this.$emit('changevalue', this.returnDetail)
    }
  }
}
</script>
