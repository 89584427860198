
<template>
  <app-side-menu :min-width="18"
    :fixed-menu="false">
    <aside class="menu"
      slot="side-menu">
      <p class="menu-label">
        <span class="title is-6">
          Select Vendor
        </span>
      </p>
      <ul class="menu-list">
        <li>
          <div class="field">
            <div class="control">
              <multiselect v-if="vendors"
                v-model="selectedVendor"
                track-by="name"
                label="name"
                :disabled="!isNew"
                :show-labels="false"
                :options="vendors"
                :options-limit="assetFilter.pageSize"
                :loading="isVendorLoading"
                :internal-search="false"
                :searchable="true"
                :allow-empty="false"
                @select="selectVendor"
                @remove="removeVendor"
                @search-change="getVendorDropdownFilter"
                placeholder="Select a Vendor">
                <span class="has-text-danger"
                  slot="noResult">Factorer not found.</span>
              </multiselect>
            </div>
          </div>
        </li>
      </ul>
      <hr>
      <!-- <div class="field is-grouped is-pulled-right">
        <div class="control">
          <button
            class="button is-primary"
            @click="onFilterClick">
            <span class="icon">
              <i class="mdi mdi-magnify mdi-18px" />
            </span>
            <span>Filter</span>
          </button>
        </div>
        <div class="control">
          <button
            class="button"
            @click="onResetClick">
            <span class="icon">
              <i class="mdi mdi-refresh mdi-18px" />
            </span>
            <span>Reset</span>
          </button>
        </div>
      </div> -->
    </aside>
  </app-side-menu>
</template>

<script>
import AppSideMenu from '@/components/AppSideMenu'
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'
import VendorService from '@/views/vendor/VendorService'
import _debounce from 'lodash.debounce'
import Guid from '@/components/Guid'
import Multiselect from 'vue-multiselect'
import ReturnPartsRoutes from './route-types'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'ReturnPartsSideMenu',
  components: {
    Multiselect,
    AppSideMenu
  },
  mixins: [NumberFiltersMixin, DateTimeFiltersMixin],
  props: {
    value: Object,
    isNew: Boolean,
    readOnly: Boolean
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value),
      vendors: [],
      vendorsName: [],
      isVendorLoading: false,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      assetFilter: {
        id: Guid.empty(),
        name: '',
        pageIndex: 1,
        pageSize: 50
      },
      selectedVendor: {
        abn: '',
        id: '',
        name: '',
        totalRows: ''
      }
    }
  },
  computed: {
    routeTypes() {
      return ReturnPartsRoutes
    }
  },
  watch: {
    // innerValue: {
    //   handler: function(newVal, oldVal) {
    //     this.$emit('input', newVal)
    //   },
    //   deep: true
    // },
    // receiptDate: function(newVal, oldVal) {
    //   if (newVal) {
    //     this.innerValue.receiptDate = this.receiptDate.toISOString()
    //   }
    // },
    // isShowReceipt: function(newVal, oldVal) {
    //   if (newVal) {
    //     this.receiptDate = new Date()
    //     this.innerValue.receiptDate = this.receiptDate.toISOString()
    //     this.innerValue.isReceiptDate = true
    //   } else {
    //     this.receiptDate = new Date()
    //     this.receiptDate.setMonth(this.receiptDate.getMonth() - 6)
    //     this.innerValue.receiptDate = this.receiptDate.toISOString()
    //     this.innerValue.isReceiptDate = false
    //   }
    // }
  },
  async created() {
    // console.log('Get Vendor')
    this.getVendorsDropdown()
    // this.selectedVendor = this.value.selectedVendor !== null ? this.value.selectedVendor : ''
  },
  methods: {
    async selectVendor(selected) {
      this.selectedVendor = selected
      this.$emit('changedetail', this.selectedVendor)
    },
    removeVendor() {},
    // onFilterClick() {
    //   this.$emit('input', true)
    // },
    // onResetClick() {
    //   this.$emit('reset')
    // },
    async getVendorsDropdown() {
      this.isVendorLoading = true
      if(this.innerValue.vendorId !== null)
      {
        this.assetFilter.id = this.innerValue.vendorId
      }

      this.vendors = await VendorService.getVendorDropdownProto2(this.assetFilter)

      if (this.assetFilter.id !== Guid.empty()) {
        this.selectedVendor = this.vendors
        this.innerValue.vendorId = this.vendors[0].id
      }
      this.isVendorLoading = false
      return this.vendors
    },
    getVendorDropdownFilter: _debounce(async function(query) {
      this.isVendorLoading = true
      this.assetFilter.name = query
      this.vendors = await VendorService.getVendorDropdownProto2(this.assetFilter)
      this.isVendorLoading = false
    }, 500)
  }
}
</script>