<template>
  <article>
    <div class="columns is-gapless">
      <div class="column">
        <p class="title is-6">Printed Remarks</p>
      </div>
      <div class="column">
        <div class="field">
          <div class="control is-pulled-right">
            <div class="is-pulled-right">
              <a class="button is-small is-success tooltip"
                data-tooltip="Add Remarks"
                @click="toggleRemarkModal()">
                <span class="icon">
                  <i class="mdi mdi-18px mdi-plus" />
                </span>
                <span>Remarks</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="field">
      <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
        :columns="columns"
        :page-index="printedFilter.pageIndex"
        :page-size="printedFilter.pageSize"
        :total-rows="printedRemarkTotalRows"
        :is-loading="isTableLoading"
        :sort-column="printedFilter.sortColumn"
        :sort-order="printedFilter.sortOrder"
        :show-header="false"
        @pageChanged="onPrintedRemarksPageChange"
        @sort="sort">
        <tr v-for="(remark, index) in pagedPrintedRemarks"
          :key="remark.remarkId">
          <td class="is-content-width">
            <span class="tag is-info">{{ $filters.formatDateTimeLocale(remark.remarkDateTime, $userInfo.locale) }}</span>
          </td>
          <td class="is-remark">
            <div v-show="editPrintedRemarkRowNumber === -1 || editPrintedRemarkRowNumber !== index">{{ remark.remarks }}</div>
            <textarea v-if="editPrintedRemarkRowNumber === index"
              @blur="editPrintedRemarkRowNumber = -1"
              class="textarea"
              v-model="remark.remarks"
              rows="3"
              v-focus-inserted />
          </td>
          <td class="has-text-centered is-content-width has-vertical-middle">
            <a
              class="button is-primary is-small is-inverted"
              @click="editPrintedRemark(index)">
              <span class="icon is-medium">
                <i
                  class="mdi mdi-24px"
                  :class="[editPrintedRemarkRowNumber !== index ? 'mdi-lead-pencil' : 'mdi-check']" />
              </span>
            </a>
            <a
              class="button is-danger is-small is-inverted"
              @click="deleteRemark(index, remark.remarkId, remark.remarkTypeId)">
              <span class="icon is-medium">
                <i class="mdi mdi-delete mdi-24px" />
              </span>
            </a>
          </td>
        </tr>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered remark-height">
              <span icon="icon is-medium">
                <i class="mdi mdi-20px mdi-dots-horizontal mdi-dark mdi-inactive" />
              </span>
            </div>
          </section>
        </template>
      </bulma-table>
      <remark-modal
        v-if="isRemarkModalActive"
        :show-remark="true"
        :show-printed-remark="true"
        :show-recurring-remark="true"
        :active.sync="isRemarkModalActive"
        @add="addRemarks"
        @cancel="toggleRemarkModal()">
        <p slot="text-title">Add Return Parts Remarks</p>
      </remark-modal>
    </div>
  </article>
</template>
<script>
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { mask } from 'vue-the-mask'
import { RemarkColumns } from './columns'
import BulmaTable from '@/components/BulmaTable'
import { RemarkTypes } from '@/enums'
import { RemarkModel } from '@/classes/viewmodels'
import { RemarkModal } from '@/components/BulmaModal'
import { DateTimeFiltersMixin } from '@/components/mixins/filters'
// import { TheMask } from 'vue-the-mask'
import _cloneDeep from 'lodash/cloneDeep'
// import DealerCreditEntryAttachmentsModal from './DealerCreditEntryAttachmentsModal'
// import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'

export default {
  name: 'ReturnPartsRemarks',
  components: {
    BulmaTable,
    RemarkModal
  },
  directives: {
    mask
  },
  mixins: [NumberFiltersMixin, DateTimeFiltersMixin],
  props: {
    value: null,
    readOnly: Boolean
  },
  data() {
    return {
      isRemarkModalActive: false,
      innerValue: _cloneDeep(this.value),
      printedFilter: {
        sortColumn: RemarkColumns[0].name,
        sortOrder: RemarkColumns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 2
      },
      editRemarkRowNumber: -1,
      editPrintedRemarkRowNumber: -1,
      isTableLoading: false,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ]
    }
  },
  computed: {
    columns() {
      return RemarkColumns
    },
    printedRemarkTotalRows() {
      return this.innerValue.printedRemarks.filter(r => r.remarkTypeId === RemarkTypes.PrintedRemark && !r.isDeleted).length
    },
    remarkTotalRows() {
      return this.innerValue.remarks.filter(r => r.remarkTypeId === RemarkTypes.Remark && !r.isDeleted).length
    },
    remarkTypes() {
      return RemarkTypes
    },
    activePrintedRemarks() {
      return this.innerValue.printedRemarks.filter(r => r.remarkTypeId === RemarkTypes.PrintedRemark && !r.isDeleted)
    },
    pagedPrintedRemarks() {
      return this.activePrintedRemarks.slice(
        (this.printedFilter.pageIndex - 1) * this.printedFilter.pageSize,
        this.printedFilter.pageIndex * this.printedFilter.pageSize
      )
    },
    activeRemarks() {
      return this.innerValue.remarks.filter(r => r.remarkTypeId === RemarkTypes.Remark && !r.isDeleted)
    },
    pagedRemarks() {
      return this.activeRemarks.slice((this.filter.pageIndex - 1) * this.filter.pageSize, this.filter.pageIndex * this.filter.pageSize)
    }
    // pagedPrintedRemarks() {
    //   return this.activePrintedRemarks.slice(
    //     (this.printedFilter.pageIndex - 1) * this.printedFilter.pageSize,
    //     this.printedFilter.pageIndex * this.printedFilter.pageSize
    //   )
    // }
  },
  watch: {
    value: {
      handler: function(newVal, oldVal) {
        this.innerValue = this.value
      },
      deep: true
    },
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  methods: {
    deleteRemark(index, remarkId, remarkType) {
      let deletedRemark
      if (remarkType === RemarkTypes.Remark) {
        deletedRemark = this.activeRemarks.find(r => r.remarkId == remarkId)
        if (deletedRemark.isNew) {
          const index = this.innerValue.remarks.findIndex(r => r.remarkId === deletedRemark.remarkId)
          this.innerValue.remarks.splice(index, 1)
        } else {
          deletedRemark.isDeleted = true
        }
      } else if (remarkType === RemarkTypes.PrintedRemark) {
        deletedRemark = this.activePrintedRemarks.find(r => r.remarkId == remarkId)
        if (deletedRemark.isNew) {
          const index = this.innerValue.printedRemarks.findIndex(r => r.remarkId === deletedRemark.remarkId)
          this.innerValue.printedRemarks.splice(index, 1)
        } else {
          deletedRemark.isDeleted = true
        }
      }
    },
    addRemarks(remark, printedRemark) {
      if (remark) {
        const newRemark = new RemarkModel(this.innerValue.creditReturnMainId, RemarkTypes.Remark, remark)
        this.innerValue.remarks.push(newRemark)
      }
      if (printedRemark) {
        const newPrintedRemark = new RemarkModel(this.innerValue.creditReturnMainId, RemarkTypes.PrintedRemark, printedRemark)
        this.innerValue.printedRemarks.push(newPrintedRemark)
      }
      this.toggleRemarkModal()
    },
    toggleRemarkModal() {
      this.isRemarkModalActive = !this.isRemarkModalActive
    },
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
    },
    onPrintedRemarksPageChange(pageIndex) {
      this.printedFilter.pageIndex = pageIndex
    },
    sort(name, order) {},
    editRemark(row) {
      this.editRemarkRowNumber !== row ? (this.editRemarkRowNumber = row) : (this.editRemarkRowNumber = -1)
      this.editPrintedRemarkRowNumber = -1
    },
    editPrintedRemark(row) {
      this.editPrintedRemarkRowNumber !== row ? (this.editPrintedRemarkRowNumber = row) : (this.editPrintedRemarkRowNumber = -1)
      this.editRemarkRowNumber = -1
    }
  }
}
</script>

<style lang="scss" scoped>
.remark-height {
  height: 1px;
}
</style>
