<template>
  <div>
    <app-detail-header :show-toggle="true"
      :show-new="false"
      :show-print="entitySummaries && entitySummaries.returnPartsItem.length != 0"
      :show-delete="entitySummaries && !entitySummaries.deleted && !entitySummaries.isNew && entitySummaries.returnPartsItem.length != 0"
      :show-save="!isSave && entitySummaries && entitySummaries.returnPartsItem.length != 0"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <return-parts-side-menu v-if="entitySummaries"
          v-model="filter"
          @changedetail="changeVendorDetail($event)"
          @filter="filterList($event)"
          @reset="resetList"
          :is-new="entitySummaries.isNew" />
      </div>
      <div class="column">
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <return-parts-detail v-if="entitySummaries"
              v-model="entitySummaries"
              :vendor-filter="filter"
              :read-only="readOnly" />
          </article>
        </div>
        <div class="tile is-horizontal">
          <div class="tile is-parent is-5">
            <article class="tile is-child box detail-page-tile">
              <return-parts-detail-value v-if="entitySummaries"
                :vendor-abn="entitySummaries.vendorAbn"
                :return-no="entitySummaries.prefix + entitySummaries.creditNo"
                :vendor-id="entitySummaries.vendorId"
                :return-date="entitySummaries.returnDate"
                :read-only="readOnly"
                @changevalue="changeEntity($event)" />
            </article>
          </div>
          <div class="tile is-parent">
            <article class="tile is-child box detail-page-tile">
              <return-parts-remarks v-if="entitySummaries"
                v-model="entitySummaries"
                :read-only="readOnly" />
            </article>
          </div>
        </div>
      </div>
    </div>
    <unsaved-modal :active.sync="isUnsavedModalActive"
      @close="closeModal()"
      @skipSave="skipSave()"
      @saveContinue="saveContinue()">
      <p slot="text-title">Unsaved Changes</p>
      <p slot="text-content">There are unsaved changes. Please select action below</p>
    </unsaved-modal>
    <save-conflict-modal :active.sync="isSaveConflictModalActive"
      @close="closeModal()"
      @reload="reloadData()">
      <p slot="text-title">Change conflict</p>
      <p slot="text-content">The data on the server is newer than the local copy. Please reload local data.</p>
    </save-conflict-modal>
    <confirm-modal v-if="entitySummaries"
      :active.sync="isConfirmModalActive"
      @ok="deleteEntity(true)"
      @cancel="deleteEntity(false)"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Delete Return Part</p>
      <p slot="text-content">
        Return Part No:
        <span class="has-text-primary has-text-weight-bold">{{ `${entitySummaries.prefix}${entitySummaries.creditNo}` }}</span> will be deleted. Continue?
      </p>
    </confirm-modal>
  </div>
</template>

<script>
import ReturnPartsSideMenu from './ReturnPartsSideMenu'
// import ReturnPartsService from './ReturnPartsService'
import Guid from '@/components/Guid'
import ReturnPartsValidation from './ReturnPartsValidation'
import ReturnPartsDetail from './ReturnPartsDetail'
import ReturnPartsDetailValue from './ReturnPartsDetailValue'
import ReturnPartsRemarks from './ReturnPartsRemarks'
import ReturnPartsRoutes from './route-types'
import AppDetailHeader from '@/components/AppDetailHeader'
import { UnsavedModal, SaveConflictModal, ConfirmModal } from '@/components/BulmaModal'
import HttpStatus from '@/components/http-status'
import { AppHeaderButtonTypes, EventHubTypes } from '@/enums'
import StoreMixin from './storeMixin'
import _cloneDeep from 'lodash/cloneDeep'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import ReturnPartsService from './ReturnPartsService'
import { KeyValuePairModel } from '@/classes/viewmodels'
import { Emailer } from '@/classes'
import StoreUtil from '@/store/utils'
import _isEmpty from 'lodash/isEmpty'

export default {
  name: 'ReturnPartsView',
  components: {
    AppDetailHeader,
    [ReturnPartsSideMenu.name]: ReturnPartsSideMenu,
    [ReturnPartsDetail.name]: ReturnPartsDetail,
    [ReturnPartsDetailValue.name]: ReturnPartsDetailValue,
    [ReturnPartsRemarks.name]: ReturnPartsRemarks,
    UnsavedModal,
    SaveConflictModal,
    ConfirmModal
  },
  mixins: [ReturnPartsValidation, StoreMixin],
  props: {
    isNew: Boolean,
    returnUrl: String,
    type: String
  },
  data() {
    return {
      entitySummaries: null,
      activeReturnPartItems: null,
      filter: {
        vendorId: null,
        isReceiptDate: true,
        receiptDate: new Date()
      },
      vendorDetail: {
        vendorId: '',
        vendorName: '',
        vendorAbn: '',
        returnNo: '',
        returnDate: new Date()
      },
      readOnly: false,
      isUnsavedModalActive: false,
      isSaveConflictModalActive: false,
      isConfirmModalActive: false,
      isSkipSave: false,
      isSaveContinue: false,
      isSave: false,
      toRoute: null // stores "to" route of beforeRouteLeave
    }
  },
  computed: {},
  watch: {},
  async created() {
    if (this.returnUrl) {
      this.persistQueries()
      this.replaceRoute(this.$route.params.creditReturnMainId)
    }
    await this.getEntity()
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          break
        case AppHeaderButtonTypes.Print:
          this.print()
          break
        case AppHeaderButtonTypes.Delete:
          this.isConfirmModalActive = true
          break
        case AppHeaderButtonTypes.Save:
          this.save()
          break
        case AppHeaderButtonTypes.Cancel:
          this.cancel()
          break
      }
    },

    async getEntity(reset = false) {
      try {

        this.$showSpinner()
        if (reset) {
          this.clearSessionStorage()
          this.clearSnapshots(this.$route.params.creditReturnMainId)
        }
        if (!this.currentSnapshot) {
          await this.getStoreItem(this.$route.params.creditReturnMainId)
        }

        this.entitySummaries = _cloneDeep(this.currentSnapshot)
        if (this.entitySummaries.returnPartsItem) {
          this.activeReturnPartItems = this.entitySummaries.returnPartsItem
        }
        if (this.entitySummaries) {
          this.filter.vendorId = null
          this.filter.vendorId = this.entitySummaries.vendorId
        }
      } catch (e) {
        this.$notification.openMessageXhrError('', e)
      }
      this.$hideSpinner()
    },
    changeVendorDetail(value) {
      this.entitySummaries.vendorName = value.name
      this.entitySummaries.vendorId = value.id
      this.entitySummaries.vendorAbn = value.abn
      this.entitySummaries.returnPartsItem = []
      this.activeReturnPartItems = []
      this.filter.isReceiptDate = value.isReceiptDate
      this.filter.receiptDate = value.receiptDate
      this.filter.vendorId = value.id === null ? Guid.empty() : value.id
    },
    changeEntity(value) {
      this.entitySummaries.vendorAbn = value.abn
      this.entitySummaries.returnDate = value.date
    },
    filterList(value) {
      if (value) {
        var date = new Date(
          new Date(this.filter.receiptDate).getFullYear(),
          new Date(this.filter.receiptDate).getMonth(),
          new Date(this.filter.receiptDate).getDate()
        ).toISOString()

        if (!this.filter.isReceiptDate) {
          this.activeReturnPartItems = this.entitySummaries.returnPartsItem
          this.entitySummaries.returnPartsItem = this.activeReturnPartItems.filter(
            (i) =>
              i.poNumber.toLowerCase().includes(this.filter.poNo.toLowerCase()) &&
              i.quoteNo.toLowerCase().includes(this.filter.quoteNo.toLowerCase()) &&
              new Date(i.recDate).toISOString() >= date
          )
        } else {
          this.activeReturnPartItems = this.entitySummaries.returnPartsItem
          this.entitySummaries.returnPartsItem = this.activeReturnPartItems.filter(
            (a) => a.poNumber.toLowerCase().includes(this.filter.poNo.toLowerCase()) && a.quoteNo.toLowerCase().includes(this.filter.quoteNo.toLowerCase())
          )
        }
      }
    },
    resetList() {
      this.filter.isReceiptDate = true
      this.filter.receiptDate = new Date()
      this.filter.poNo = ''
      this.filter.quoteNo = ''
      this.entitySummaries.returnPartsItem = this.activeReturnPartItems
    },
    async save(isDelete) {
      const title = 'Return Parts'
      let check = this.isCheck()

      if (!check) {
        this.$notification.openNotificationWithType('danger', title, 'Validation errors. Returning quantity is greater than Received quantity')
        return false
      }

      if (this.entitySummaries.returnPartsItem.filter(i => i.returning === 0).length > 0) {
        this.$notification.openNotificationWithType('danger', title, 'Validation errors. Returning quantity cannot be zero')
        return false
      }

      if (!this.$v.entitySummaries.vendorAbn.validAbn) {
        this.$notification.openNotificationWithType('danger', title, 'Validation errors. ABN is Invalid')
        return false
      }
      try {
        this.$showSpinner('Saving...')
        let response
        this.saveSnapshot(_cloneDeep(this.entitySummaries))
        if (this.entitySummaries.isNew) {
          response = await ReturnPartsService.postEntity(this.entitySummaries)
        } else if (this.snapshotDiff) {
          response = await ReturnPartsService.putReturnParts(_cloneDeep(this.entitySummaries), this.snapshotDiff)
        } else {
          this.$notification.openNotificationWithType('warning', title, 'No changes. Not saved')
        }

        if (response && response.status === HttpStatus.NO_CONTENT) {
          if (isDelete) {
            this.$notification.openNotificationWithType('success', title, isDelete ? 'Return Part deleted' : 'Save successful')
            this.$router.push({
              name: ReturnPartsRoutes.ReturnPartsListView.name
            })
          } else {
            await this.getEntity(true)
            this.filter.vendorId = this.entitySummaries.vendorId
            this.$notification.openNotificationWithType('success', title, isDelete ? 'Return Part deleted' : 'Save successful')
          }
        } else if (response && response.status === HttpStatus.CREATED) {
          await this.getEntity(true)
          this.filter.vendorId = this.entitySummaries.vendorId
          this.isSave = true
          this.readOnly = true
          this.$notification.openNotificationWithType('success', title, 'Save successful')
        }
        this.$hideSpinner()
        this.$eventHub.$emit(EventHubTypes.EntitySaved)
        return true
      } catch (e) {
        this.$hideSpinner()
        if (e.response.request.status === HttpStatus.CONFLICT) {
          this.isSaveConflictModalActive = true
        } else {
          this.$notification.openMessageXhrError('Return Parts', e)
        }
        return false
      }
    },
    isCheck() {
      for (let index = 0; index < this.entitySummaries.returnPartsItem.length; index++) {
        const isNew = this.entitySummaries.returnPartsItem[index].isNewLine
        const currReturn = this.entitySummaries.returnPartsItem[index].return
        const returning = this.entitySummaries.returnPartsItem[index].returning
        const returned = this.entitySummaries.returnPartsItem[index].returned
        const received = this.entitySummaries.returnPartsItem[index].rced
        // const totalReturn = this.entitySummaries.returnPartsItem[index].rced - this.entitySummaries.returnPartsItem[index].returned
        // const totalReturned = return1 + returned
        if (received === returned) {
          if (returning > currReturn) {
            return false
          }
        }
        if (received !== returned) {
          if (currReturn === returned) {
            if (returning > received) {
              return false
            }
          } else {
            if ((returning + returned > received && isNew) || (returned - currReturn + returning > received && !isNew)) {
              return false
            }
          }
        }
      }
      return true
    },

    deleteEntity(confirmDelete) {
      this.isConfirmModalActive = false
      if (confirmDelete) {
        this.entitySummaries.deleted = true
        // save snapshots immediately not waiting for debounce
        this.save(true)
      }
    },
    cancel() {
      this.saveSnapshot(_cloneDeep(this.entitySummaries))
      if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue) {
        this.isUnsavedModalActive = true
      } else {
        if (this.returnUrl) {
          this.clearSessionStorage()
          this.clearSnapshots(this.entitySummaries.creditReturnMainId)
          window.location.href = `${process.env.VUE_APP_ROOT_URI}${this.returnUrl}`
        } else if (this.toRoute) {
          this.$router.push(this.toRoute.fullPath)
        } else if (this.returnRoute && !_isEmpty(this.returnRoute)) {
          this.$router.push(this.returnRoute.fullPath)
        } else {
          this.$router.push({
            name: ReturnPartsRoutes.ReturnPartsListView.name
          })
        }
      }
    },
    print() {
      const params = {
        REPORTTITLE: 'Credit Return',
        CompanyID: this.$userInfo.companyId,
        CREDITNO: this.entitySummaries.prefix + this.entitySummaries.creditNo,
        VendorID: this.entitySummaries.vendorId
      }

      // Create KeyValuePairs and add to vuex for later retrieval by PrintPreviewEx component
      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)
      const parameterId = this.$guid.newGuid()
      StoreUtil.setLocalStorage(parameterId, 'parameters', keyValuePairs)

      const emailer = new Emailer()
      // emailer.assetId = this.entitySummaries.creditReturnMainId
      emailer.subject = 'Credit Return'
      emailer.reportName = 'AT_CREDITRETURN'
      this.addEmailer(emailer)
      StoreUtil.setLocalStorage(emailer.id, 'emailer', emailer)

      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: 'AT_CREDITRETURN' },
        query: { parameterId: parameterId, emailerId: emailer.id }
      })
      // const routeData = this.$router.resolve({
      //   name: PrintPreviewRoutes.PrintPreview.name,
      //   params: { reportName: 'AT_CREDITRETURN' },
      //   query: { parameterId: parameterId, emailerId: emailer.id, isNewTab: true }
      // })
      // window.open(routeData.href, '_blank')
      this.$hideSpinner()
    },
    async saveContinue() {
      this.isUnsavedModalActive = false
      this.isSkipSave = false
      this.isSaveContinue = true
      this.isSaveContinue = await this.save(false)
      if (this.isSaveContinue) {
        this.cancel()
      }
    },
    skipSave() {
      this.isUnsavedModalActive = false
      this.isSkipSave = true
      this.cancel()
    },
    redirectToRoute() {
      if (this.toRoute) {
        this.$router.push(this.toRoute.fullPath)
      } else if (!this.entitySummaries.isNew) {
        this.cancel()
      }
    },
    closeModal() {
      this.isUnsavedModalActive = false
      this.isSaveConflictModalActive = false
      this.isSaveContinue = false
    },
    async reloadData() {
      this.isSaveConflictModalActive = false
      this.isSaveContinue = false
      await this.getEntity(true)
      this.$eventHub.$emit(EventHubTypes.EntityReload)
    },
    removeQueries() {
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|returnparts|returnUrl`)
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|returnparts|type`)
    },
    clearSessionStorage() {
      this.removeQueries()
    },

    persistQueries() {
      if (this.returnUrl) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|quickinvoice|returnUrl`, this.returnUrl)
      }
      if (this.type) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|quickinvoice|type`, this.type)
      }
    },

    replaceRoute(id) {
      const newMeta = Object.assign(this.$route.meta, {
        returnUrl: this.returnUrl,
        type: this.type
      })
      this.$router.replace({
        name: ReturnPartsRoutes.ReturnPartsDetail.name,
        params: { invoiceId: id },
        meta: newMeta
      })
    },
    clearRouteMeta() {
      // Reset returnUrl meta in case user clicked on other V2
      if (this.$route.meta.returnUrl) {
        this.$route.meta.returnUrl = null
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.saveSnapshot(_cloneDeep(this.entitySummaries))
    if (this.snapshotDiff && this.isSkipSave && this.isSaveContinue) {
      this.$router.replace(from.path)
      this.toRoute = to
      this.isUnsavedModalActive = true
    } else {
      // Clean vuex store for now
      this.clearSnapshots(this.entitySummaries.creditReturnMainId)
      this.clearSessionStorage()
      this.clearRouteMeta()
      if (to.name !== PrintPreviewRoutes.PrintPreview.name) {
        this.setReturnRoute({})
      }
      next()
    }
  }
}
</script>
